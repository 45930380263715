//******************************************************************************
export const sendContactInfo = async (msg) =>
{
  const response = await fetch('https://app.velacto.com/fid-api_OSKC3ROQAAAMBKABBSDGYPXM', {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(msg),
  });
  return await response.json();
}
//******************************************************************************